


































































































import { Component, Vue, Watch } from "vue-property-decorator";
import LsDialog from "@/components/ls-dialog.vue";
import LsPagination from "@/components/ls-pagination.vue";
import List from "@/components/shop/goods-manage/list.vue";
import {
    apiGoodsCategoryLists,
    apiGoodsCategoryDel,
    apiGoodsCategoryStatus,
} from "@/api/goods";
@Component({
    components: {
        LsDialog,
        LsPagination,
        List,
    },
})
export default class Category extends Vue {
    data: any = [];
    defaultProps = {
        children: "sons",
        label: "name",
    };

    drawer = false;

    packgeCategory = {
        title: "",
        id: "",
    };

    // 获取列表
    async getGoodsCategoryLists(): Promise<void> {
        const res = await apiGoodsCategoryLists({
            packge_id: this.$route.query.packge_id,
        });
        if (res) this.data = res.lists;
    }

    // 修改状态
    async goodsCategoryStatus(id: number, status: number): Promise<void> {
        await apiGoodsCategoryStatus({ id: id, is_show: status });
    }

    // 删除
    async goodsCategoryDel(id: number): Promise<void> {
        const res = await apiGoodsCategoryDel({
            id: id,
            packge_id: this.$route.query.packge_id,
        });

        if (res) {
            this.getGoodsCategoryLists();
        }
    }

    // 展开分类
    showGoodsSelect(obj: any) {
        this.packgeCategory.title = obj.name;
        this.packgeCategory.id = obj.id;
        this.drawer = true;
    }
    @Watch("$route")
    routeParamsChanged(newParams: any, oldParams: any): void {
        if (newParams.path == "/goods/operation_details/category_manage") {
            this.getGoodsCategoryLists();
        }
    }
    created() {
        this.getGoodsCategoryLists();
    }
}
