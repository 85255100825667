





















































































































































































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import lsPagination from "@/components/ls-pagination.vue";
import LsDialog from "@/components/ls-dialog.vue";
import { RequestPaging } from "@/utils/util";

import {
    apiGoodsPackgeGoodsLists,
    apiGoodsPackgeGoodsPackgeGoodsLists,
    apiGoodsPackgeGoodsAddCategoryGoods,
    apiGoodsPackgeGoodsDeleteCategoryGoods,
    apiGoodsPackgeGoodsDeleteGoods,
    apiGoodsPackgeGoodsStatusGoods,
} from "@/api/shop";
import { apiGoodsCategoryLists, apiCategoryLists } from "@/api/goods";
@Component({
    components: {
        lsPagination,
        LsDialog,
    },
})
export default class goods_manage_list extends Vue {
    @Prop({ default: 1 }) listType!: number;
    @Prop({ default: "" }) packgeCategoryId!: number;
    @Prop({ default: "" }) categryName!: string;

    pager: RequestPaging = new RequestPaging();
    searchObj: any = {
        packge_id: "", //运营包id
        name: "", //商品名称
        supplier_name: "", //自营管理供应商
        status: "", //商品状态
        min_price: "", //最低价
        max_price: "", //最高价
        min_profit: "", //最低利润
        max_profit: "", //最高利润
        is_supplier_up: "", //是否为平台/自营1平台0自营
        category_id: "", //分类id
    };

    categoryList: any[] = [];
    categoryListFlat: any[] = [];
    checkList = [];

    //多选列表
    multipleSelection: any[] = [];
    //全选所有
    allCheck: boolean = false;

    //获取选品列表
    getList(page?: number) {
        page && (this.pager.page = page);
        this.searchObj.packge_id = this.$route.query.packge_id;
        if (this.listType == 2)
            this.searchObj.packge_category_id = this.packgeCategoryId;
        this.pager.request({
            callback:
                this.listType == 1
                    ? apiGoodsPackgeGoodsPackgeGoodsLists
                    : apiGoodsPackgeGoodsLists,
            params: {
                ...this.searchObj,
            },
        });
    }

    //获取分类列表
    async getCategoryList(): Promise<void> {
        const res =
            this.listType == 1
                ? await apiGoodsCategoryLists({ ...this.$route.query })
                : await apiCategoryLists({});

        // res?.lists.forEach((item: any) => {
        //   item.sons &&
        //     item.sons.forEach((sitem: any) => {
        //       delete sitem.sons;
        //     });
        // });
        this.categoryList = res?.lists;
        this.categoryListFlatFun(res?.lists);
    }

    //分类过滤
    categoryListFlatFun(tree: any) {
        tree = JSON.parse(JSON.stringify(tree));
        let queen: any = [];
        let out = [];
        queen = queen.concat(tree);
        while (queen.length) {
            let first = queen.shift();
            if (first.sons) {
                queen = queen.concat(first.sons);
                delete first.sons;
            }
            out[first.id] = first.name;
        }
        this.categoryListFlat = out;
    }

    //新增
    async goodsPackgeGoodsAddCategoryGoods(arr: Array<object>): Promise<void> {
        if (arr.length === 0) {
            this.$message.warning("请选中商品");
            return;
        }
        const data: any = {
            category_array: [],
        };

        arr.map((item: any) => {
            data.category_array.push({
                goods_id: item.id,
                category_id: this.packgeCategoryId,
                packge_id: this.$route.query.packge_id,
            });
        });
        const res = await apiGoodsPackgeGoodsAddCategoryGoods(data); //新增
        if (res) {
            this.$message.success(`商品加入${this.categryName} 分类成功`);
            this.getList();
        }
    }
    //删除
    async goodsPackgeGoodsDeleteCategoryGoods(
        obj: any,
        index: number
    ): Promise<void> {
        const data: any = {
            category_array: [],
        };
        this.checkList.map((item) => {
            data.category_array.push({
                goods_id: obj.id,
                category_id: item,
                packge_id: obj.packge_id,
            });
        });

        const res = await apiGoodsPackgeGoodsDeleteCategoryGoods(data); //删除
        if (res) {
            const popoverRef: any = this.$refs[`popover-${index}`];
            popoverRef.doClose();
            this.getList();
            this.checkList = [];
        }
    } //删除

    //全部删除
    async goodsPackgeGoodsDeleteGoods(arr: Array<object>): Promise<void> {
        if (arr.length === 0) {
            this.$message.warning("请选中商品");
            return;
        }
        const data: any = {
            category_array: [],
        };

        arr.map((item: any) => {
            data.category_array.push({
                goods_id: item.id,
                packge_id: item.packge_id,
            });
        });

        const res = await apiGoodsPackgeGoodsDeleteGoods(data);

        if (res) this.getList();
    }

    //选中商品1-添加 2-全部删除
    // selectGoods(type: number) {
    //   const paneTable: any = this.$refs.paneTable;
    //   type == 1
    //     ? this.goodsPackgeGoodsAddCategoryGoods(paneTable.selection)
    //     : this.goodsPackgeGoodsDeleteGoods(paneTable.selection);
    // }

    // 重置搜索
    reset(): void {
        Object.keys(this.searchObj).map((key) => {
            this.$set(this.searchObj, key, "");
        });
        this.getList(1);
    }

    //分类过滤
    categoryListFlatFilters(str: string) {
        const arr = str.split(",");
        let res: any = "";
        arr.map((item: any) => {
            res += `<p>${this.categoryListFlat[item]}</p>`;
        });
        return res;
    }

    //左下角全选
    allCheckbox(value: boolean) {
        const tableRef = this.$refs.paneTable as any;
        value ? tableRef.toggleAllSelection() : tableRef.clearSelection();
    }

    //表格全选事件
    handleSelectionChange(value: any) {
        this.multipleSelection = value;
        let checkedCount = value.length;
        this.allCheck = checkedCount === this.pager.lists.length;
    }
    //商品上下架
    async standUpDown(row: Array<object>, status: number) {
        if (row.length === 0) {
            this.$message.warning("请选中商品");
            return;
        }
        let data: any = {
            ids: [],
            packge_id: this.$route.query.packge_id,
            status: status,
        };

        row.map((item: any) => {
            data.ids.push(item.id);
        });
        const res = await apiGoodsPackgeGoodsStatusGoods(data);
        if (res) this.getList();
    }

    created() {
        this.getList(1);
        this.getCategoryList();
    }
}
