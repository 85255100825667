var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ls-category"},[_c('div',{staticClass:"ls-category__top ls-card"},[_c('el-alert',{attrs:{"title":"温馨提示：1.用户可以根据商品分类搜索商品，请正确创建分类；2.点击分类名称前符号，显示该商品分类的下级分类。","type":"info","show-icon":"","closable":false}})],1),_c('div',{staticClass:"ls-category__content m-t-16 ls-card"},[_c('div',{staticClass:"ls-content__btns"},[_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.$router.push({
                        path: '/goods/category_edit',
                        query: Object.assign({}, _vm.$route.query),
                    })}}},[_vm._v("新增商品分类")]),_c('el-tree',{attrs:{"data":_vm.data,"props":_vm.defaultProps,"node-key":"id","default-expand-all":"","expand-on-click-node":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var data = ref.data;
return _c('span',{staticClass:"custom-tree-node"},[_c('span',[_vm._v(" "+_vm._s(data.name)+" "),_c('span',{staticClass:"count"},[_vm._v("("+_vm._s(data.mcs_source_goods_count)+")")])]),_c('span',[_c('el-button',{attrs:{"type":"text","size":"mini","icon":"el-icon-s-goods"},on:{"click":function($event){return _vm.showGoodsSelect(data)}}},[_vm._v(" 选品  ")]),_c('el-switch',{attrs:{"active-value":1,"inactive-value":0,"active-text":"显示","inactive-text":"隐藏"},on:{"change":function($event){return _vm.goodsCategoryStatus(data.id, data.status)}},model:{value:(data.status),callback:function ($$v) {_vm.$set(data, "status", $$v)},expression:"data.status"}}),_vm._v("   "),_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.$router.push({
                                    path: '/goods/category_edit',
                                    query: Object.assign({}, {id: data.id}, _vm.$route.query),
                                })}}},[_vm._v(" 编辑 ")]),_c('ls-dialog',{staticClass:"m-l-10 inline",on:{"confirm":function($event){return _vm.goodsCategoryDel(data.id)}}},[_c('el-button',{attrs:{"slot":"trigger","type":"text","size":"mini"},slot:"trigger"},[_vm._v("删除 ")])],1)],1)])}}])})],1)]),_c('el-drawer',{attrs:{"title":_vm.packgeCategory.title + '------选品页',"visible":_vm.drawer,"modal":false,"destroy-on-close":"","modal-append-to-body":false,"size":"100%"},on:{"update:visible":function($event){_vm.drawer=$event}}},[_c('List',{attrs:{"listType":2,"packgeCategoryId":_vm.packgeCategory.id,"categryName":_vm.packgeCategory.title}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }